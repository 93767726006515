import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  getCategories,
  deleteCategory,
  updateCategory,
} from '../../services/categories';
import { MdErrorOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MdDeleteOutline } from 'react-icons/md';

const UpdateCategory = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const [category, setCategory] = useState(null);
  const [subcategories, setSubcategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ });
  const [subcategoriesToDelete, setSubcategoriesToDelete] = useState([]);
  const [subcategoriesToRender, setSubcategoriesToRender] = useState([]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categories = await getCategories();
        const foundCategory = categories?.data.find((cat) => (cat.id === categoryId));
        if (foundCategory) {
          setCategory(foundCategory);
          setSubcategories(foundCategory.attributes.subcategories)
          setSubcategoriesToRender(foundCategory.attributes.subcategories)
        } else {
          setError('Não foi possível encontrar a categoria');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, [categoryId]);

  const handleDeleteCategory = (subcatId) => {
    const subcategoryToDelete = subcategories.filter(
      (subcat) => subcat.id === subcatId
    );
    setSubcategoriesToRender(subcategories.filter((subcat) => subcat.id !== subcatId));
    setSubcategoriesToDelete([
      ...subcategoriesToDelete,
      ...subcategoryToDelete,
    ]);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: { name: e.target.value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let hasError = false;

    // Delete subcategories
    for (const subcatToDelete of subcategoriesToDelete) {
      try {
        await deleteCategory(subcatToDelete.id);
        console.log('Category deleted successfully');
      } catch (error) {
        setError(error.message);
        setSubcategoriesToRender(subcategories)
        setSubcategoriesToDelete([])
        hasError = true;
      }
    }

    // Update categories
    try {
      for (const [categoryId, updatedCategoryData] of Object.entries(formData)) {
        const response = await updateCategory(categoryId, updatedCategoryData);
        console.log('Category updated:', response);
      }
    } catch (error) {
      setError(error.message);
      hasError = true;
    } finally {
      setLoading(false)
      if (!hasError) {
        navigate('/categorias');
      }
    }
  };

  const renderForm = () => (
    <form className='w-full sm:w-5/8 lg:w-4/6' onSubmit={handleSubmit}>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Nome</label>
        <input
          className='bg-almostWhite border-b py-2 outline-none'
          type='text'
          name={category.attributes.id}
          defaultValue={category.attributes.name}
          value={formData[category.attributes.id]?.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className='flex flex-col mt-10'>
        <label className='mb-2 font-medium'>Subcategorias</label>
        {subcategoriesToRender.length > 0 ? (
          subcategoriesToRender.map((subcat) => (
            <div key={subcat.id} className='flex gap-10'>
              <input
                className='bg-almostWhite border-b py-2 outline-none mb-2 grow'
                type='text'
                name={subcat.id}
                defaultValue={subcat.name}
                value={formData[subcat.id]?.name}
                onChange={handleInputChange}
                required
              />
              <Link
                onClick={() => handleDeleteCategory(subcat.id)}
                className='flex justify-center items-center aspect-square rounded-full mt-2 mb-1 p-2 md:p-3 border border-almostBlack max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
              >
                <MdDeleteOutline className='text-base md:text-lg' />
              </Link>
            </div>
          ))
        ) : (
          <div className='flex gap-10 items-center'>
            <div>Sem subcategorias</div>
          </div>
        )}
      </div>
      <div className='w-full text-center'>
        <input
          className='rounded-full py-2 px-3 mt-12 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite hover:cursor-pointer'
          type='submit'
          value='Guardar'
        />
      </div>
    </form>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center text-secondaryBrown pt-40 pb-16 min-h-screen px-5 md:px-24'>
        <div className='flex flex-col items-center justify-center w-full sm:w-5/8 lg:w-4/6'>
          <h2 className='text-2xl'>EDITAR CATEGORIA</h2>
        </div>
        {error && (
          <div className='mt-4 flex gap-2 items-center text-red-900 w-full sm:w-5/8 lg:w-4/6'>
            <MdErrorOutline className='text-xl' />
            {error.message ? error.message : error}
          </div>
        )}
        {renderForm()}
      </div>
    </>
  );
};

export default UpdateCategory;
