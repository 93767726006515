import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import ProjectList from './components/projects/ProjectList';
import Login from './components/auth/Login';
import NotFound from './components/NotFound';
import InternalServerError from './components/InternalServerError';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './actions/auth';
import Navbar from './components/Navbar';
import CategoryList from './components/categories/CategoryList';
import NewCategory from './components/categories/NewCategory';
import NewProject from './components/projects/NewProject';
import UpdateCategory from './components/categories/UpdateCategory';
import UpdateProject from './components/projects/UpdateProject';
import DisplayError from '../src/components/DisplayError';

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        await dispatch(checkAuth());
      } catch (error) {
        setError(error)
      }
    };

    fetchAuthStatus();
  }, [dispatch]);

  const ProtectedRoute = () => {
    return isLoggedIn ? <Outlet /> : <Navigate to={'login'} replace />;
  };

  const NonProtectedRoute = () => {
    return isLoggedIn ? <Navigate to={'projetos'} replace /> : <Outlet />;
  };

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <div className='App'>
      <Router>
        {isLoggedIn && <Navbar />}
        <Routes>
          <Route element={<NonProtectedRoute />}>
            <Route exact path='/login' Component={Login} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route exact path='/projetos' element={<ProjectList />} />
            <Route exact path='/novo-projeto' element={<NewProject />} />
            <Route
              exact
              path='/editar-projeto/:projectId'
              element={<UpdateProject />}
            />
            <Route exact path='/categorias' element={<CategoryList />} />
            <Route exact path='/nova-categoria' element={<NewCategory />} />
            <Route
              exact
              path='/editar-categoria/:categoryId'
              element={<UpdateCategory />}
            />
          </Route>
          <Route path='/404' element={<NotFound />} />
          <Route path='*' element={<NotFound />} />
          <Route path='/500' element={<InternalServerError />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
