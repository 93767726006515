import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../utils/constants';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteOutline, MdErrorOutline } from 'react-icons/md';
import { deleteProject, getProjects } from '../../services/projects';
import { getCategories } from '../../services/categories';
import LoadingSpinner from '../../components/LoadingSpinner';
import DisplayError from '../../components/DisplayError';
import logo from '../../assets/sizz-logo.png';

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [projectsError, setProjectsError] = useState(null);

  const [categories, setCategories] = useState([]);
  const [categoriesError, setCategoriesError] = useState(null);

  const [errorDeleting, setErrorDeleting] = useState(null);

  // get projects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectsData = await getProjects();
        const projects = projectsData?.data || [];

        // Projects organized by year and alphabetically
        const sortedProjects = projects.sort((a, b) => {
          if (a?.attributes.year !== b?.attributes.year) {
            return b?.attributes.year - a?.attributes.year;
          }
          return a?.attributes.name.localeCompare(b?.attributes.name);
        });

        setProjects(sortedProjects);
      } catch (error) {
        setProjectsError(error);
      } finally {
        setProjectsLoading(false);
      }
    };

    fetchData();
  }, []);

  // get categories
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData?.data);
      } catch (error) {
        setCategoriesError(error);
      }
    };

    fetchData();
  }, []);

  if (projectsLoading) {
    return <LoadingSpinner />;
  }

  if (projectsError) {
    return <DisplayError error={projectsError} />;
  }

  if (categoriesError) {
    return <DisplayError error={categoriesError} />;
  }

  const renderProjectCategory = (project) => {
    if (project?.attributes.category.parent_id) {
      const projectSubCategoryId = project?.attributes.category.id;
      const projectCategoryId = project?.attributes.category.parent_id;
      const projectCategory = categories.find(
        (cat) => cat?.attributes.id === projectCategoryId
      );
      const projectSubCategory = projectCategory?.attributes.subcategories.find(
        (cat) => cat.id === projectSubCategoryId
      );

      return projectSubCategory
        ? `${projectCategory?.attributes.name}, ${projectSubCategory.name}`
        : projectCategory?.attributes.name;
    }

    const projectCategoryId = project?.attributes.category.id;
    const projectCategory = categories.find(
      (cat) => cat?.attributes.id === projectCategoryId
    );

    return projectCategory?.attributes.name;
  };

  const handleDeleteProject = async (projectId) => {
    const confirmDelete = window.confirm("Tem a certeza que quer apagar este projeto?");
    if (confirmDelete) {
      try {
        await deleteProject(projectId);
        setProjects(
          projects.filter((project) => project?.attributes.id !== projectId)
        );
        console.log('Project deleted successfully');
      } catch (error) {
        setErrorDeleting(error);
      }
    }
  };

  const renderTable = () => (
    <div className='mt-12 w-full'>
      <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr>
                <th scope='col' className='relative py-3.5 px-3'>
                  <span className='sr-only'>Imagem de Capa</span>
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-bold'
                >
                  Nome
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-bold'
                >
                  Categoria
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-bold'
                >
                  Descrição / Fase
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-bold'
                >
                  Localização
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-bold'
                >
                  Ano
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-bold'
                >
                  Fotografia
                </th>
                <th scope='col' className='relative py-3.5 px-3'>
                  <span className='sr-only'>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {projects.map((project) => (
                <tr key={project?.attributes.id}>
                  <td className='pr-3 py-4 text-sm font-medium'>
                    {project?.attributes.images.length ? (
                      <img
                        src={`${config.API_URL}/${project?.attributes.images[0].url}`}
                        alt='Capa'
                        className='aspect-square w-44 min-w-20 rounded-sm'
                      />
                    ) : (
                      <img
                        src={logo}
                        alt='Sem capa'
                        className='aspect-square w-44 min-w-20 rounded-sm p-2 xl:p-8 bg-gray'
                      />
                    )}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {project?.attributes.name}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {renderProjectCategory(project)}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {project?.attributes.description}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {project?.attributes.location}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {project?.attributes.year}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {project?.attributes.photography}
                  </td>
                  <td className='relative py-4 pl-3 text-right text-sm font-medium'>
                    <div className='flex gap-1'>
                      <Link
                        to={`/editar-projeto/${project?.attributes.id}`}
                        className='aspect-square rounded-full p-2 md:p-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
                      >
                        <FaRegEdit className='text-sm md:text-base' />
                      </Link>
                      <Link
                        onClick={() =>
                          handleDeleteProject(project?.attributes.id)
                        }
                        className='aspect-square rounded-full p-2 md:p-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
                      >
                        <MdDeleteOutline className='text-base md:text-lg' />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='flex flex-col justify-center items-center text-secondaryBrown pt-40 pb-16 min-h-screen px-5 md:px-24'>
        <div className='flex items-center w-full justify-between'>
          <h2 className='text-2xl'>PROJETOS</h2>
          <div>
            <Link
              to='/novo-projeto'
              className='rounded-full py-2 px-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
            >
              Novo Projeto
            </Link>
          </div>
        </div>
        {errorDeleting && (
          <div className='mt-4 flex gap-2 items-center text-red-900 w-full'>
            <MdErrorOutline className='text-xl' />
            {errorDeleting.message}
          </div>
        )}
        {projects.length > 0 ? (
          renderTable()
        ) : (
          <div className='mt-10 w-full'>Sem projetos</div>
        )}
      </div>
    </>
  );
};

export default ProjectList;
