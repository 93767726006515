import React, { useState, useEffect } from 'react';
import {
  updateProject,
  getProjects,
  setProjectPrimaryImage,
  deleteProjectImage,
} from '../../services/projects';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getCategories } from '../../services/categories';
import { MdErrorOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { config } from '../../utils/constants';
import { FaTimes } from 'react-icons/fa';

const NewProject = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [existingProject, setExistingProject] = useState(null);
  const [existingProjectCategory, setExistingProjectCategory] = useState(null);
  const [existingProjectSubcategory, setExistingProjectSubcategory] =
    useState(null);
  const [existingProjectCoverImageId, setExistingProjectCoverImageId] =
    useState(null);
  const [project, setProject] = useState({});
  const [chosenCategory, setChosenCategory] = useState();
  const [chosenSubcategory, setChosenSubcategory] = useState();
  const [projectImages, setProjectImages] = useState(
    existingProject?.attributes.images || []
  );
  const [imagesToDelete, setImagesToDelete] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projects = await getProjects();
        const foundProject = projects?.data.find(
          (project) => project.id === projectId
        );

        if (foundProject) {
          setExistingProject(foundProject);
          if (foundProject?.attributes.category.parent_id) {
            const category = categories.find(
              (cat) =>
                Number(cat.id) === foundProject?.attributes.category.parent_id
            );
            const subcategory = category?.attributes.subcategories.find(
              (cat) => Number(cat.id) === foundProject?.attributes.category.id
            );
            setExistingProjectCategory(category);
            setExistingProjectSubcategory(subcategory);
          } else {
            const category = categories.find(
              (cat) => Number(cat.id) === foundProject?.attributes.category.id
            );
            setExistingProjectCategory(category);
          }
          setProjectImages(foundProject?.attributes.images);
          setExistingProjectCoverImageId(
            foundProject?.attributes.images[0].id
          );
        } else {
          setError('Não foi possível encontrar o projeto');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId, categories]);

  const chosenCategorySubcategories = () => {
    if (!chosenCategory) {
      return;
    }
    return categories.filter(
      (cat) => cat?.attributes.id === Number(chosenCategory)
    )[0]?.attributes.subcategories;
  };

  const showSubcategories = chosenCategory
    ? chosenCategorySubcategories().length > 0
    : !!existingProject?.attributes.category.parent_id;

  const subcategoriesToRender = chosenCategory
    ? chosenCategorySubcategories()
    : existingProjectCategory?.attributes.subcategories;

  const imageUrl = (img) => {
    if (img.id) {
      return `${config.API_URL}/${img.url}`
    }
    return img.url
  }

  const handleDeleteImage = (image) => {
    if (image.id) {
      const imageToDelete = projectImages.filter((img) => img.id === image.id);
      setProjectImages(projectImages.filter((img) => img.id !== image.id));
      setImagesToDelete([...imagesToDelete, ...imageToDelete]);
    }
    setProjectImages(projectImages.filter((img) => img.url !== image.url));
  };

  const handleInputChange = (e) => {
    if (e.target.name === 'category') {
      setChosenCategory(e.target.value);
      setChosenSubcategory();
    } else if (e.target.name === 'subcategory') {
      setChosenSubcategory(e.target.value);
    } else if (e.target.name === 'images') {
      const newImages = Array.from(e.target.files).map((file) => ({
        url: URL.createObjectURL(file),
        file: file,
      }));
      setProjectImages([...projectImages, ...newImages]);
    } else {
      setProject({
        ...project,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    const formData = new FormData();
    if (
      imagesToDelete.length > 0 ||
      existingProjectCoverImageId !==
        existingProject?.attributes.images[0].id ||
      Object.keys(project).length > 0 ||
      chosenCategory ||
      chosenSubcategory ||
      projectImages.some((image) => !image.id)
    ) {
      setLoading(true);
    } else {
      console.log('Nothing changed');
      navigate('/projetos');
    }

    for (const key in project) {
      if (key !== 'images') {
        formData.append(key, project[key]);
      }
    }

    if (chosenCategory || chosenSubcategory) {
      formData.append(
        'category_id',
        chosenSubcategory ? chosenSubcategory : chosenCategory
      );
    }

    for (const image of projectImages) {
      if (!image.id) {
        formData.append('images[]', image.file);
      }
    }

    for (const image of imagesToDelete) {
      try {
        await deleteProjectImage(existingProject?.attributes.id, {
          image_id: image.id,
        });
        console.log('Image successfully deleted from project');
      } catch (error) {
        setError(error);
        hasError = true
      } finally {
        setLoading(false);
        if (!hasError) {
          navigate('/projetos');
        }
      }
    }

    if (
      existingProjectCoverImageId !== existingProject?.attributes.images[0].id
    ) {
      try {
        await setProjectPrimaryImage(existingProject?.attributes.id, {
          image_id: existingProjectCoverImageId,
        });
        console.log('Primary image successfully updated');
      } catch (error) {
        setError(error);
        hasError = true;
      } finally {
        setLoading(false);
        if (!hasError) {
          navigate('/projetos');
        }
      }
    }

    if (Array.from(formData.entries()).length > 0) {
      try {
        const response = await updateProject(
          existingProject?.attributes.id,
          formData
        );
        console.log('Project updated:', response);
      } catch (error) {
        setError(error);
        hasError = true;
      } finally {
        setLoading(false);
        if (!hasError) {
          navigate('/projetos');
        }
      }
    }
  };

  const renderForm = () => (
    <form className='w-full sm:w-5/8 lg:w-4/6' onSubmit={handleSubmit}>
      <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-10 mt-12'>
        <div className='flex flex-col'>
          <label className='font-medium'>Nome</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='text'
            name='name'
            defaultValue={existingProject?.attributes.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className='flex flex-col'>
          <label className='font-medium'>Localização</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='text'
            name='location'
            defaultValue={existingProject?.attributes.location}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Descrição / Fase</label>
        <input
          className='bg-almostWhite border-b py-2 outline-none'
          type='text'
          name='description'
          defaultValue={existingProject?.attributes.description}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Categoria</label>
        <select
          className='bg-almostWhite border-b py-2 outline-none'
          name='category'
          defaultValue={existingProjectCategory?.attributes.id}
          onChange={handleInputChange}
          required
        >
          <option value='' disabled>
            Escolher categoria
          </option>
          {categories?.map((category) => (
            <option
              value={category?.attributes.id}
              key={category?.attributes.id}
              selected={
                category?.attributes.id ===
                existingProjectCategory?.attributes.id
              }
            >
              {category?.attributes.name}
            </option>
          ))}
        </select>
      </div>
      {showSubcategories && (
        <div className='flex flex-col mt-10'>
          <label className='font-medium'>Subcategoria</label>
          <select
            className='bg-almostWhite border-b py-2 outline-none'
            name='subcategory'

            value={
              chosenSubcategory ||
              (chosenCategory ? '' : existingProjectSubcategory?.id)
            }
            onChange={handleInputChange}
            required
          >
            <option value='' disabled>
              Escolher subcategoria
            </option>
            {subcategoriesToRender?.map((category) => (
              <option
                key={category.id}
                value={category.id}
                selected={category.id === existingProjectSubcategory?.id}
              >
                {category.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-10 mt-10'>
        <div className='flex flex-col'>
          <label className='font-medium'>Ano</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='number'
            name='year'
            defaultValue={existingProject?.attributes.year}
            onChange={handleInputChange}
          />
        </div>
        <div className='flex flex-col'>
          <label className='font-medium'>Fotógrafo</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='text'
            name='photography'
            defaultValue={existingProject?.attributes.photography}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Fotografias</label>
        <div className='grid grid-cols-3 xl:grid-cols-4 gap-4 w-full py-2'>
          {projectImages.map((img) => (
            <div key={img.id} className='w-full'>
              <div
                style={{
                  backgroundImage: `url(${imageUrl(img)})`,
                }}
                className={`relative rounded-sm group container flex justify-center items-center mx-auto bg-no-repeat bg-center bg-cover aspect-square ${
                  img.id ? '' : 'border-dashed border-2 border-primaryBlue'
                }`}
              >
                {img.id === existingProjectCoverImageId && (
                  <div className='absolute top-2 left-1 whitespace-nowrap rounded-full font-medium py-1.5 px-[9px] text-xs sm:text-sm border border-almostBlack max-w-fit bg-almostBlack text-almostWhite'>
                    Capa
                  </div>
                )}
                {/* hover effect */}
                <div
                  className='relative rounded-sm opacity-0 group-hover:opacity-100 w-full h-full flex justify-center items-center mx-auto bg-no-repeat bg-center bg-cover text-almostWhite'
                  style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.8)), url(${imageUrl(
                      img
                    )})`,
                  }}
                >
                  {img.id && img.id !== existingProjectCoverImageId && (
                    <button
                      type='button'
                      onClick={() => setExistingProjectCoverImageId(img.id)}
                      className='absolute top-2 left-1 rounded-full whitespace-nowrap font-medium py-1.5 px-[9px] text-xs sm:text-sm border border-almostWhite max-w-fit hover:bg-almostWhite hover:text-almostBlack hover:border-almostBlack hover:cursor-pointer'
                    >
                      Capa
                    </button>
                  )}
                  {img.id === existingProjectCoverImageId && (
                    <div className='absolute top-2 left-1 rounded-full whitespace-nowrap font-medium py-1.5 px-[9px] text-xs sm:text-sm border border-almostBlack max-w-fit bg-almostBlack text-almostWhite'>
                      Capa
                    </div>
                  )}
                  {img.id !== existingProjectCoverImageId && (
                    <button
                      type='button'
                      onClick={() => handleDeleteImage(img)}
                      className='absolute top-2 right-1 aspect-square rounded-full py-1.5 px-[9px] text-sm border border-almostWhite max-w-fit hover:bg-almostWhite hover:text-almostBlack hover:border-almostBlack hover:cursor-pointer'
                    >
                      <FaTimes />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className='relative group'>
            <input
              className='opacity-0 w-full h-full absolute inset-0 hover:cursor-pointer'
              type='file'
              name='images'
              accept='image/*'
              multiple
              onChange={handleInputChange}
            />
            <button
              type='button'
              className='w-full text-6xl text-darkerGray py-2 px-4 bg-gray aspect-square rounded-sm shadow-sm hover:cursor-pointer'
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className='w-full text-center'>
        <input
          className='rounded-full py-2 px-3 mt-12 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite hover:cursor-pointer'
          type='submit'
          value='Guardar'
        />
      </div>
    </form>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center text-secondaryBrown pt-40 pb-16 min-h-screen px-5 md:px-24'>
        <div className='flex flex-col items-center justify-center w-full sm:w-5/8 lg:w-4/6'>
          <h2 className='text-2xl'>EDITAR PROJETO</h2>
        </div>
        {error && (
          <div className='mt-4 flex gap-2 items-center text-red-900 w-full sm:w-5/8 lg:w-4/6'>
            <MdErrorOutline className='text-xl' />
            {error.message || error}
          </div>
        )}
        {renderForm()}
      </div>
    </>
  );
};

export default NewProject;
