import axios from 'axios';
import { config } from '../utils/constants';
import { getToken } from '../actions/auth';

export const getProjects = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/projects`);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao carregar a lista de projetos');
  } catch (error) {
    throw new Error('Erro ao carregar a lista de projetos');
  }
};

export const createProject = async (projectData) => {
  try {
    const token = getToken();
    const response = await axios.post(
      `${config.API_URL}/projects`,
      projectData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao criar um novo projeto');
  } catch (error) {
    throw new Error('Erro ao criar um novo projeto');
  }
};

export const updateProject = async (projectId, updatedProjectData) => {
  try {
    const token = getToken();
    const response = await axios.put(
      `${config.API_URL}/projects/${projectId}`,
      updatedProjectData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao editar o projeto');
  } catch (error) {
    throw new Error('Erro ao editar o projeto');
  }
};

export const deleteProject = async (projectId) => {
  try {
    const token = getToken();
    const response = await axios.delete(
      `${config.API_URL}/projects/${projectId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error(
      'Erro ao apagar o projeto.'
    );
  } catch (error) {
    throw new Error(
      'Erro ao apagar o projeto.'
    );
  }
};

export const setProjectPrimaryImage = async (projectId, imageId) => {
  try {
    const token = getToken();
    const response = await axios.post(
      `${config.API_URL}/projects/${projectId}/set_primary_image`,
      imageId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao definir imagem de capa.');
  } catch (error) {
    throw new Error('Erro ao definir imagem de capa.');
  }
};

export const deleteProjectImage = async (projectId, imageId) => {
  try {
    const token = getToken();
    const response = await axios.post(
      `${config.API_URL}/projects/${projectId}/delete_image`,
      imageId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao apagar imagem do projeto.');
  } catch (error) {
    throw new Error('Erro ao apagar imagem do projeto.');
  }
};
