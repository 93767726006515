import React from 'react';
import { MdErrorOutline } from 'react-icons/md';

const DisplayError = ({ error }) => {
  return (
    <div className='flex flex-col justify-center items-center h-screen text-primaryBlue'>
      <MdErrorOutline className='text-3xl mb-4' />
      Error: {error?.message ? error?.message : error}
    </div>
  );
};

export default DisplayError;
