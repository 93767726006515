import React from 'react';
import { connect } from 'react-redux';
import logo from '../assets/sizz-logo.png';
import Logout from './auth/Logout';
import { Link } from 'react-router-dom';

const Navbar = ({ loggedIn, currentUser }) => {
  const navbarItems = [
    { name: 'Projetos', route: '/projetos', id: 1 },
    { name: 'Categorias', route: '/categorias', id: 2 },
  ];

  return (
    <div className='fixed w-full h-24 flex justify-between items-center px-5 md:px-24 bg-bgNeutral z-10 border-b border-secondaryLight'>
      <div className='flex justify-center'>
        <img src={logo} alt='Sizz Design Logo' className='w-16 md:w-20' />
      </div>

      <ul className='flex gap-1 md:gap-8 flex-col md:flex-row'>
        {navbarItems.map((item) => (
          <li
            key={`menu${item.id}`}
            className='text-secondaryBrown font-medium text-sm md:text-base'
          >
            <Link to={item.route}>{item.name.toUpperCase()}</Link>
          </li>
        ))}
      </ul>

      <div>
        {loggedIn && (
          <div className='flex flex-col gap-1 md:gap-2 items-end'>
            {currentUser.email}
            <Logout />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { loggedIn, currentUser } }) => {
  return { loggedIn, currentUser };
};

export default connect(mapStateToProps)(Navbar);
