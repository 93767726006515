import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import LoadingSpinner from '../../components/LoadingSpinner'
import DisplayError from '../../components/DisplayError';
import { deleteCategory, getCategories } from '../../services/categories';
import { MdErrorOutline } from 'react-icons/md';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorDeleting, setErrorDeleting] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        const sortedCategories = categoriesData?.data.sort((a, b) => {
          return a.attributes.name.localeCompare(b.attributes.name);
        });
        setCategories(sortedCategories);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <DisplayError error={error} />;
  }

  const handleDeleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm(
      'Tem a certeza que quer apagar esta categoria? Ao apagar a categoria as respetivas subcategorias também serão apagadas.'
    );
    if (confirmDelete) {
      try {
        await deleteCategory(categoryId);
        setCategories(
          categories.filter((category) => category.attributes.id !== categoryId)
        );
        console.log('Category deleted successfully');
      } catch (error) {
        setErrorDeleting(error);
      }
    }
  };

  const renderTable = () => (
    <div className='mt-8 w-full'>
      <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-bold'
                >
                  Categoria
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-bold'
                >
                  Subcategorias
                </th>
                <th scope='col' className='relative py-3.5 px-3'>
                  <span className='sr-only'>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {categories.map((category) => (
                <tr key={category.attributes.id}>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {category.attributes.name}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    {category.attributes.subcategories.map((subcat) => (
                      <div className='flex' key={subcat.id}>
                        {subcat.name}
                      </div>
                    ))}
                  </td>
                  <td className='relative py-4 pl-3 text-right text-sm font-medium'>
                    <div className='flex gap-1'>
                      <Link
                        to={`/editar-categoria/${category.attributes.id}`}
                        className='aspect-square rounded-full p-2 md:p-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
                      >
                        <FaRegEdit className='text-sm md:text-base' />
                      </Link>
                      <Link
                        onClick={() =>
                          handleDeleteCategory(category.attributes.id)
                        }
                        className='aspect-square rounded-full p-2 md:p-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
                      >
                        <MdDeleteOutline className='text-base md:text-lg' />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='flex flex-col justify-center items-center text-secondaryBrown pt-40 pb-16 min-h-screen px-5 md:px-24'>
        <div className='flex items-center w-full justify-between'>
          <h2 className='text-2xl'>CATEGORIAS</h2>
          <div>
            <Link
              to='/nova-categoria'
              className='rounded-full py-2 px-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
            >
              Nova Categoria
            </Link>
          </div>
        </div>
        {errorDeleting && (
          <div className='mt-4 flex gap-2 items-center text-red-900 w-full'>
            <MdErrorOutline className='text-xl' />
            {errorDeleting.message}
          </div>
        )}
        {categories.length > 0 ? (
          renderTable()
        ) : (
          <div className='mt-10 w-full'>Sem categorias</div>
        )}
      </div>
    </>
  );
};

export default CategoryList;
