import React, { useState, useEffect } from 'react';
import { createProject } from '../../services/projects';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getCategories } from '../../services/categories';
import { MdErrorOutline } from 'react-icons/md';

const NewProject = () => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [project, setProject] = useState({
    name: '',
    description: '',
    location: '',
    year: 0,
    photography: '',
    images: [],
  });
  const [chosenCategory, setChosenCategory] = useState();
  const [chosenSubcategory, setChosenSubcategory] = useState();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    if (e.target.name === 'category') {
      setChosenCategory(e.target.value);
    } else if (e.target.name === 'subcategory') {
      setChosenSubcategory(e.target.value);
    } else if (e.target.name === 'images') {
      setProject({
        ...project,
        images: [...project.images, ...Array.from(e.target.files)],
      });
    } else {
      setProject({
        ...project,
        [e.target.name]: e.target.value,
      });
    }
  };

  const chosenCategorySubcategories = () => {
    if (!chosenCategory) {
      return;
    }
    return categories.filter(
      (cat) => cat.attributes.id === Number(chosenCategory)
    )[0].attributes.subcategories;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    for (const key in project) {
      if (key !== 'images') {
        formData.append(key, project[key]);
      }
    }

    formData.append('category_id', chosenSubcategory ? chosenSubcategory : chosenCategory)

    for (const image of project.images) {
      formData.append('images[]', image);
    }

    try {
      const response = await createProject(formData);
      navigate('/projetos');
      console.log('New project created:', response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false)
    }
  };

  const renderForm = () => (
    <form className='w-full sm:w-5/8 lg:w-4/6' onSubmit={handleSubmit}>
      <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-10 mt-12'>
        <div className='flex flex-col'>
          <label className='font-medium'>Nome</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='text'
            name='name'
            value={project.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className='flex flex-col'>
          <label className='font-medium'>Localização</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='text'
            name='location'
            value={project.location}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Descrição / Fase</label>
        <input
          className='bg-almostWhite border-b py-2 outline-none'
          type='text'
          name='description'
          value={project.description}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Categoria</label>
        <select
          className='bg-almostWhite border-b py-2 outline-none'
          name='category'
          defaultValue={''}
          onChange={handleInputChange}
          required
        >
          <option value='' disabled>
            Escolher categoria
          </option>
          {categories.map((category) => (
            <option value={category.attributes.id} key={category.attributes.id}>
              {category.attributes.name}
            </option>
          ))}
        </select>
      </div>
      {chosenCategory && chosenCategorySubcategories().length > 0 && (
        <div className='flex flex-col mt-10'>
          <label className='font-medium'>Subcategoria</label>
          <select
            className='bg-almostWhite border-b py-2 outline-none'
            name='subcategory'
            defaultValue={''}
            onChange={handleInputChange}
            required
          >
            <option value='' disabled>
              Escolher subcategoria
            </option>
            {chosenCategorySubcategories().map((category) => (
              <option value={category.id} key={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-10 mt-10'>
        <div className='flex flex-col'>
          <label className='font-medium'>Ano</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='number'
            name='year'
            value={project.year === 0 ? '' : project.year}
            onChange={handleInputChange}
          />
        </div>
        <div className='flex flex-col'>
          <label className='font-medium'>Fotógrafo</label>
          <input
            className='bg-almostWhite border-b py-2 outline-none'
            type='text'
            name='photography'
            value={project.photography}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Fotografias</label>
        <input
          className='py-2 outline-none z-50'
          type='file'
          name='images'
          accept='image/*'
          multiple
          onChange={handleInputChange}
          required
        />
      </div>
      <div className='w-full text-center'>
        <input
          className='rounded-full py-2 px-3 mt-12 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite hover:cursor-pointer'
          type='submit'
          value='Guardar'
        />
      </div>
    </form>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center text-secondaryBrown pt-40 pb-16 min-h-screen px-5 md:px-24'>
        <div className='flex flex-col items-center justify-center w-full sm:w-5/8 lg:w-4/6'>
          <h2 className='text-2xl'>NOVO PROJETO</h2>
        </div>
        {error && (
          <div className='mt-4 flex gap-2 items-center text-red-900 w-full sm:w-5/8 lg:w-4/6'>
            <MdErrorOutline className='text-xl' />
            {error.message}
          </div>
        )}
        {renderForm()}
      </div>
    </>
  );
};

export default NewProject;
