import React, { useState, useEffect } from 'react';
import { createCategory } from '../../services/categories';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getCategories } from '../../services/categories';
import { MdErrorOutline } from 'react-icons/md';

const NewCategory = () => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ name: '' });
  const [categoryType, setCategoryType] = useState();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    if (e.target.name === 'type') {
      setCategoryType(e.target.value);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await createCategory({ category: formData });
      navigate('/categorias');
      console.log('New category created:', response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false)
    }
  };

  const renderForm = () => (
    <form className='w-full sm:w-5/8 lg:w-4/6' onSubmit={handleSubmit}>
      <div className='flex flex-col mt-8'>
        <label className='font-medium'>Tipo</label>
        <select
          className='bg-almostWhite border-b py-2 outline-none'
          name='type'
          onChange={handleInputChange}
          defaultValue={''}
          required
        >
          <option value='' disabled>
            Escolher tipo
          </option>
          <option value='categoria'>Categoria</option>
          <option value='subcategoria'>Subcategoria</option>
        </select>
      </div>
      {categoryType === 'subcategoria' && (
        <div className='flex flex-col mt-10'>
          <label className='font-medium'>Categoria-mãe</label>
          <select
            className='bg-almostWhite border-b py-2 outline-none'
            name='parent_id'
            value={formData.parent_id}
            defaultValue={''}
            onChange={handleInputChange}
            required
          >
            <option value='' disabled>
              Escolher categoria-mãe
            </option>
            {categories.map((category) => (
              <option
                value={category.attributes.id}
                key={category.attributes.id}
              >
                {category.attributes.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className='flex flex-col mt-10'>
        <label className='font-medium'>Nome</label>
        <input
          className='bg-almostWhite border-b py-2 outline-none'
          type='text'
          name='name'
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className='w-full text-center'>
        <input
          className='rounded-full py-2 px-3 mt-12 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite hover:cursor-pointer'
          type='submit'
          value='Guardar'
        />
      </div>
    </form>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center text-secondaryBrown pt-40 pb-16 min-h-screen px-5 md:px-24'>
        <div className='flex flex-col items-center justify-center w-full sm:w-5/8 lg:w-4/6'>
          <h2 className='text-2xl'>NOVA CATEGORIA</h2>
          <p className='mt-8 mb-2'>
            É possível criar categorias e subcategorias. Para criar uma
            subcategoria é necessário criar primeiro a categoria que queremos
            que seja a sua categoria-mãe.
          </p>
        </div>
        {error && (
          <div className='mt-4 flex gap-2 items-center text-red-900 w-full sm:w-5/8 lg:w-4/6'>
            <MdErrorOutline className='text-xl' />
            {error.message}
          </div>
        )}
        {renderForm()}
      </div>
    </>
  );
};

export default NewCategory;
