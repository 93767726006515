import axios from 'axios';
import { config } from '../utils/constants'
import { getToken } from '../actions/auth';

export const getCategories = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/categories`);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao carregar a lista de categorias');
  } catch (error) {
    throw new Error('Erro ao carregar a lista de categorias');
  }
};

export const createCategory = async (categoryData) => {
  try {
    const token = getToken();
    const response = await axios.post(
      `${config.API_URL}/categories`,
      categoryData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao criar uma nova categoria');
  } catch (error) {
    throw new Error('Erro ao criar uma nova categoria');
  }
};

export const updateCategory = async (categoryId, updatedCategoryData) => {
  try {
    const token = getToken();
    const response = await axios.put(
      `${config.API_URL}/categories/${categoryId}`,
      updatedCategoryData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error('Erro ao editar a categoria');
  } catch (error) {
    throw new Error('Erro ao editar a categoria');
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const token = getToken();
    const response = await axios.delete(
      `${config.API_URL}/categories/${categoryId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error(
      'Erro ao apagar a categoria. Não se esqueça que não é possível apagar categorias com projetos associados.'
    );
  } catch (error) {
    throw new Error(
      'Erro ao apagar a categoria. Não se esqueça que não é possível apagar categorias com projetos associados.'
    );
  }
};
