import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/auth';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/sizz-logo.png';
import { MdErrorOutline } from 'react-icons/md';

const Login = ({ dispatchLoginUser, warning }) => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = credentials;

    dispatchLoginUser({ email, password })
      .then(() => navigate('/projetos'))
      .catch(() => setError(true));
  };

  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center'>
      {warning && (
        <div className='mb-8 flex gap-2 items-center text-red-900 w-11/12 max-w-2xl mx-auto '>
          <MdErrorOutline className='text-xl' />
          You need to login to view this page
        </div>
      )}
      <img src={logo} alt='Sizz Design Logo' className='w-20 md:w-28' />
      <form onSubmit={handleSubmit} className='w-11/12 max-w-2xl mx-auto mt-8'>
        <h2 className='text-3xl text-center mb-8'>LOGIN</h2>
        {error && (
          <div className='mb-8 flex gap-2 items-center text-red-900'>
            <MdErrorOutline className='text-xl' />
            Invalid email or password
          </div>
        )}
        <fieldset>
          <label className='block mb-2' htmlFor='email'>
            Email:
          </label>
          <input
            type='text'
            name='email'
            id='email'
            className='bg-almostWhite w-full border-b py-2 outline-none'
            onChange={handleChange}
            value={credentials.email}
          />
        </fieldset>
        <fieldset>
          <label className='block mb-2 mt-8' htmlFor='password'>
            Password:
          </label>
          <input
            type='password'
            name='password'
            id='password'
            className='bg-almostWhite w-full border-b py-2 outline-none'
            onChange={handleChange}
            value={credentials.password}
          />
        </fieldset>
        <div className='w-full text-center mt-8'>
          <input
            className='rounded-full py-2 px-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite hover:cursor-pointer'
            type='submit'
            value='Log In'
          />
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLoginUser: (credentials) => dispatch(loginUser(credentials)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
