import { AUTHENTICATED, NOT_AUTHENTICATED } from '.';
import { config } from '../utils/constants';

const setToken = (token) => {
  localStorage.setItem('token', token);
  localStorage.setItem('lastLoginTime', new Date(Date.now()).getTime());
};

export const getToken = () => {
  const now = new Date(Date.now()).getTime();
  const twentyFourHours = 1000 * 60 * 24 * 60;
  const timeSinceLastLogin = now - localStorage.getItem('lastLoginTime');
  if (timeSinceLastLogin < twentyFourHours) {
    return localStorage.getItem('token');
  }
};

export const loginUser = (credentials) => {
  return (dispatch) => {
    return fetch(`${config.API_URL}/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: credentials }),
    }).then((res) => {
      if (res.ok) {
        setToken(res.headers.get('Authorization'));
        return res
          .json()
          .then((userJson) =>
            dispatch({ type: AUTHENTICATED, payload: userJson })
          );
      }

      return res.json().then((errors) => {
        dispatch({ type: NOT_AUTHENTICATED });
        return Promise.reject(errors);
      });
    });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    return fetch(`${config.API_URL}/logout`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
    }).then((res) => {
      if (res.ok) {
        return dispatch({ type: NOT_AUTHENTICATED });
      }

      return res.json().then((errors) => {
        dispatch({ type: NOT_AUTHENTICATED });
        return Promise.reject(errors);
      });
    });
  };
};

export const checkAuth = () => {
  return (dispatch) => {
    return fetch(`${config.API_URL}/current_user`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
    }).then((res) => {
      if (res.ok) {
        return res
          .json()
          .then((user) => dispatch({ type: AUTHENTICATED, payload: user }));
      }
      if (res.status === 401) {
        dispatch({ type: NOT_AUTHENTICATED });
        return Promise.resolve(null);
      }

      return Promise.reject(new Error('Error during authentication'));
    });
  };
};
